import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageHeader from '../components/pageHeader'
const Omoss = () => (
  <Layout>
    <Seo title='Om trafikskolan' />
    <PageHeader text='Om trafikskolan' />
    <div className='container'>
      <div className='row'>
        <div className='col'>
          Vi är en körskola
        </div>
      </div>
    </div>
  </Layout>
)

export default Omoss
